import React, { useEffect } from 'react';
import parse from 'html-react-parser';

const Audio = ({ id, contentHTML }) => {

  useEffect(() => {
    if (contentHTML) {

      let box = document.getElementById(id);
      let audio = box.querySelector('audio');
      let timeline = box.querySelector('.timeline');
      let playerButton = box.querySelector('.player_button');
      let soundButton = box.querySelector('.sound_button');

      //fix
      playerButton.innerHTML = 'play_arrow';
      soundButton.innerHTML = 'volume_up';
      timeline.removeAttribute('style');


      function toggleAudio() {
        if (audio.paused) {
          audio.play();
          playerButton.innerHTML = 'pause';
        } else {
          audio.pause();
          playerButton.innerHTML = 'play_arrow';
        }
      }
      playerButton.addEventListener('click', toggleAudio);


      function changeTimelinePosition() {
        const percentagePosition = (100 * audio.currentTime) / audio.duration;
        timeline.style.backgroundSize = `${percentagePosition}% 100%`;
        timeline.value = percentagePosition;
      }
      audio.ontimeupdate = changeTimelinePosition;


      function audioEnded() {
        playerButton.innerHTML = 'play_arrow';
      }
      audio.onended = audioEnded;


      function changeSeek() {
        const time = (timeline.value * audio.duration) / 100;
        audio.currentTime = time;
      }
      timeline.addEventListener('input', changeSeek);


      function toggleSound() {
        audio.muted = !audio.muted;
        soundButton.innerHTML = audio.muted ? 'volume_off' : 'volume_up';
      }
      soundButton.addEventListener('click', toggleSound);

    }

  }, [contentHTML]);

  return (
    (!contentHTML)
      ? parse("<span>Audio</span>")
      : (contentHTML.rota)
        ? <div className="audio_player">
          <div className='audio_text'>{contentHTML.name}</div>

          <audio src={'/media' + contentHTML.rota} type={'audio/' + contentHTML.ext}></audio>

          <input className="timeline" type="range" min="0"  max="100" value="0" preLoad="true" />

          <div className="controls">
            <span className="player_button material-icons">play_arrow</span>
            <span className="sound_button material-icons">volume_up</span>
          </div>
        </div>
        : parse(contentHTML)
  );
};

export default Audio;
