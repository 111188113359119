import React from 'react';
import parse from 'html-react-parser';

const Iframe = ({ contentHTML }) => {


  return (
    (!contentHTML)
      ? parse("<span>iFrame</span>")
      : (contentHTML.rota)
        ? <iframe src={contentHTML.rota} allowFullScreen="" referrerpolicy="strict-origin-when-cross-origin" ></iframe>
        : parse(contentHTML)
  );
};

export default Iframe; 