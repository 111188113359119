export default function check_accordeon() {

    let components = Array.from(document.querySelectorAll('.edit_component'));
    let accordeons = Array.from(document.querySelectorAll('.edit_component[data-name="Accordion"]'));
    let finals = Array.from(document.querySelectorAll('.edit_component[data-name="Accordion"]:has(>.lastacc)')); //finals

    //verificar si esta entre un acordeon
    for (var i = 0; i < components.length; i++) {
        if (!accordeons.includes(components[i])) { //no es elemento de acordeon
            for (var b = i; b >= 0; b--) {
                if (accordeons.includes(components[b])) { //es acordeon
                    if (!finals.includes(components[b])) {
                        for (var c = i; c < components.length; c++) {
                            if (finals.includes(components[c])) {
                                if (components[b].children[0].classList.contains('closed')) components[i].style.display = 'none';
                                components[i].style.marginLeft = '2rem';
                                components[i].style.marginRight = '2rem';
                                components[i].classList.add('acc_box');
                                break;
                            } else {
                                components[i].classList.remove('acc_box');
                                components[i].classList.remove('acc_final');
                                components[i].removeAttribute('style');
                            }
                        }
                    } break;
                } else { 
                    components[i].removeAttribute('style'); 
                }
            }
        } else {
            //clean
            components[i].removeAttribute('style');
            if (components[i].classList.contains('acc_box')) components[i].classList.remove('acc_box');
            if (components[i].classList.contains('acc_final')) components[i].classList.remove('acc_final');
            if (finals.includes(components[i])) components[i].classList.add('acc_final');
        }
    }
}
