
export default function open_click(elem) {
    elem.addEventListener("click", function (e) {
        
        elem.classList.toggle('closed');

        var listItems = document.querySelectorAll('.edit_component');
        let allAcc = Array.from(document.querySelectorAll('.edit_component[data-name="Accordion"]'));

        //buscamos los index
        var startIndex = Array.prototype.indexOf.call(listItems, allAcc[allAcc.indexOf(e.currentTarget.parentNode)]);
        var endIndex = Array.prototype.indexOf.call(listItems, allAcc[allAcc.indexOf(e.currentTarget.parentNode) + 1]);

        //obtenemos la lista en medio
        var sublist = Array.prototype.slice.call(listItems, startIndex + 1, endIndex);

        //toggle visibilidad 
        sublist.forEach(function (item) {

            if (item.style.display === "none") {
                item.style.display = "block"; // Show the element
            } else {
                item.style.display = "none"; // Hide the element
            }
        });

    });
}