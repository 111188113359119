import React, { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../../Main";
import axios from "axios";
import "../../../admin/directorio/directorio.css";
import TopBack from "./TopBack";

const Directorio = () => {
  const { cancelLoading } = useContext(LoadingContext);
  const { startLoading } = useContext(LoadingContext);
  const [search, setSearch] = useState("");
  const [allData, setAllData] = useState([]);
  const [allDataFilter, setAllDataFilter] = useState([]);

  const dataTopBack = {
    img: "banner_directorio.jpg",
    categoria: "37",
    titulo: "Directorio",
    publicado: "2023-10-13 10:46:36",
  };

  const getDirectoryDepends = () => {
    startLoading();
    axios.post("/directorio/get").then((res) => {
      if (res.data.data && res.data.data.length > 0) {
        setAllData(res.data.data);
        setAllDataFilter(res.data.data);
      } else {
        setAllData([]);
        setAllDataFilter([]);
      }
      cancelLoading();
    });
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    const filteredData = allData.filter((item) => {
      if (item.name.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (item.depends.length > 0) {
        const dependsMatch = item.depends.some((dep) =>
          dep.name.toLowerCase().includes(search.toLowerCase())
        );
        if (dependsMatch) {
          return true;
        }
      }
      if (item.depends.length > 0) {
        const extensionsMatch = item.depends.some((dep) =>
          dep.extensions.some((ext) =>
            ext.ext.toLowerCase().includes(search.toLowerCase())
          )
        );
        if (extensionsMatch) {
          return true;
        }
      }
      return false;
    });
    setAllDataFilter(filteredData);
  };

  useEffect(() => {
    getDirectoryDepends();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [search]);

  return (
    <>
      <TopBack backName="Inicio" dataTopBack={dataTopBack} backTo="/" />
      <div className="content_box">
        <div
          style={{
            width: "100%",
            paddingTop: "3rem",
            paddingBottom: "3rem",
            paddingLeft: "10vw",
            paddingRight: "10vw",
            boxSizing: "border-box",
          }}
        >
          <div className="inputDirectorioContainer">
            <div className="searchIconContainer">
              <svg
                className="searchIcon"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              className="inputDirectorio"
              onChange={handleChangeSearch}
              value={search}
              style={{ boxSizing: "border-box" }}
            />
          </div>

          <div className="backgroundDirectorio">
            <div
              className="titleDirectorio"
              style={{ boxSizing: "border-box" }}
            >
              <h3>DEPENDENCIAS</h3>
              <h3>EXT</h3>
            </div>

            <div className="containerDirectorio">
              {allDataFilter.length > 0 &&
                allDataFilter.map((group, index) => (
                  <div key={index}>
                    <div
                      className="groupTitle"
                      style={{ boxSizing: "border-box" }}
                    >
                      <h3 style={{ textAlign: "center", width: "100%" }}>
                        {group.name.toUpperCase()}
                      </h3>
                    </div>
                    {group.depends.length > 0 ? (
                      <div className="seccionContainer">
                        {group.depends.map((depend, item) => (
                          <div
                            key={item}
                            style={{
                              backgroundColor:
                                item % 2 === 0 ? "#BDBCBC" : "#B3B3B3",
                              boxSizing: "border-box",
                            }}
                            className="containerDepends"
                          >
                            <div className="dependName">
                              <h3 style={{ margin: 0 }}>
                                {depend.name.toUpperCase()}
                              </h3>
                            </div>

                            <div className="ext" style={{ marginRight: 20 }}>
                              <h3 style={{ margin: 0 }}>
                                {depend.extensions.length > 0 &&
                                  depend.extensions.map((ext, index) =>
                                    index + 1 === depend.extensions.length
                                      ? `${ext.ext}`
                                      : `${ext.ext} - `
                                  )}
                              </h3>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="noDepends"></div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Directorio;
