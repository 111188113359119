import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import '../../../../../node_modules/react-multi-carousel/lib/styles.css';



export default function Links() {

    const [isHovered, setIsHovered]= useState(false);

      

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
          onMove,
          carouselState: { currentSlide, deviceType }
        } = rest;
        return (
            <div className={`button_arrow-right noselect ${isHovered ? "show": "hide"}`}
                
                onClick={() => onClick()}
            >
                 <span className="material-icons">arrow_forward_ios</span>
            </div>
        );
    };

      const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
          onMove,
          carouselState: { currentSlide, deviceType }
        } = rest;
        return (
            <div className={`button_arrow  noselect ${isHovered ? "show": "hide"}`} onClick={() => onClick()} >
                <span className="material-icons">arrow_back_ios</span>
            </div>
        );
      };



    return (
        <section className="slide" data-s="links" style={{padding: 0}} onMouseEnter={()=>{setIsHovered(true)}} onMouseLeave={()=>{setIsHovered(false)}}>
            <div id="grid_link">
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay={true}
                    autoPlaySpeed={2500}
                    centerMode={false}
                    customRightArrow={<CustomRightArrow/>}
                    customLeftArrow={<CustomLeftArrow/>}
                    className=""
                    containerClass="carrousel"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 4,
                        partialVisibilityGutter: 40
                        },
                        mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                        },
                        tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                        }
                        }}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                    <div className="self_link">
                        <a href="https://new.ufpso.edu.co/banco_docente" target="_blank" rel="noopener noreferrer">
                            <span className="material-icons">save</span>
                            <div className="link_text">Banco de Datos</div>
                        </a>
                    </div>
                    <div className="self_link">
                    <a href="https://new.ufpso.edu.co/concurso_docente" target="_blank" rel="noopener noreferrer">
                        <span className="material-icons">class</span>
                        <div className="link_text">Concurso Docente</div>
                    </a>
                    </div>
                    <div className="self_link">
                    <a href="https://new.ufpso.edu.co/rendicion_de_cuentas" target="_blank" rel="noopener noreferrer">
                        <span className="material-icons">account_balance</span>
                        <div className="link_text">Rendición de cuentas</div>
                    </a>    
                    </div>
                    <div className="self_link">
                    <a href="https://new.ufpso.edu.co/premios_periodismo" target="_blank" rel="noopener noreferrer">
                        <span className="material-icons">view_timeline</span>
                        <div className="link_text">Premios Regionales de Periodismo</div>
                    </a>
                    </div>
                    <div className="self_link">
                    <a href="https://interconectividad.ufpso.edu.co/" target="_blank" rel="noopener noreferrer">
                        <span className="material-icons">manage_search</span>
                        <div className="link_text">Interconectividad</div>
                    </a>
                    </div>
                    <div className="self_link">
                    <a href="https://new.ufpso.edu.co/exa_final" rel="noopener noreferrer">
                        <span className="material-icons">quiz</span>
                        <div className="link_text">Examenes Finales</div>
                    </a>
                    </div>
                    <div className="self_link">
                        <span className="material-icons">collections</span>
                        <div className="link_text">Fotografias</div>
                    </div>
                    
                    <div className="self_link">
                        <a href="https://new.ufpso.edu.co/transparencia_acceso_informacion" target="_blank" rel="noopener noreferrer">
                            <span className="material-icons">find_in_page</span>
                             <div className="link_text">Transparencia y Acceso a la Información Pública</div>
                        </a>
                    </div>

                    <div className="self_link">
                        <a href="https://new.ufpso.edu.co/proceso_democratico_2024" rel="noopener noreferrer">
                            <span className="material-icons">how_to_vote</span>
                             <div className="link_text">Proceso Democrático</div>
                        </a>
                    </div>
                    
                </Carousel>
            </div>
        </section>
    );
}
