import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './styles/historico_noticias.css';

import { LoadingContext } from '../../Main';

const HistoricoNoticias = () => {

  const { cancelLoading } = useContext(LoadingContext);

  return (
    <section className="slide" data-s="historico_noticias">
      <ul id="historico_noticias">
        <li>
          <picture>
            <img src="imgs/DSC_4526.jpg" alt="" />
          </picture>
          <span>
            <h3>Ampliación de fechas de Inscripciones para la Especialización en Sistemas de Gestión Integral HSEQ</h3>
            <p>Si deseas ampliar tus horizontes laborales y personales esta es tu oportunidad, la Especialización en Sistemas de Gestión Integral HSEQ amplía su fecha de preinscripciones hasta el día 20 de junio e inscripciones hasta el 27 de junio de 2021.</p>
          </span>
        </li>
        <li>
          <picture>
            <img src="imgs/DSC_4526.jpg" alt="" />
          </picture>
          <span>
            <h3>Ampliación de fechas de Inscripciones para la Especialización en Sistemas de Gestión Integral HSEQ</h3>
            <p>Si deseas ampliar tus horizontes laborales y personales esta es tu oportunidad, la Especialización en Sistemas de Gestión Integral HSEQ amplía su fecha de preinscripciones hasta el día 20 de junio e inscripciones hasta el 27 de junio de 2021.</p>
          </span>
        </li>
        <li>
          <picture>
            <img src="imgs/DSC_4526.jpg" alt="" />
          </picture>
          <span>
            <h3>Ampliación de fechas de Inscripciones para la Especialización en Sistemas de Gestión Integral HSEQ</h3>
            <p>Si deseas ampliar tus horizontes laborales y personales esta es tu oportunidad, la Especialización en Sistemas de Gestión Integral HSEQ amplía su fecha de preinscripciones hasta el día 20 de junio e inscripciones hasta el 27 de junio de 2021.</p>
          </span>
        </li>
      </ul>
    </section>
  );
};
export default HistoricoNoticias;

