import React, { useEffect } from "react";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import ButtonSubMenu from "./ButtonSubMenu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "./submenu.css";
import ButtonMenu from "./ButtonMenu";

const SubMenu_ = ({ submenu }) => {
  const renderMenu = (menu) => {
    console.log(menu);
    return (
      <div key={menu.uuid}>
        {menu.type === 2 ? (
          <Menu
            menuButton={
              <MenuButton>
                <ButtonSubMenu title={menu.name} />
              </MenuButton>
            }
            transition
          >
            {menu.subMenus.map((submenu) => renderMenu(submenu))}
          </Menu>
        ) : (
          <ButtonMenu
            name={menu.name}
            href={menu.type === 1 ? menu.nav : menu.URL}
            type={menu.type}
          />
        )}
      </div>
    );
  };

  return <div className="container">{submenu.map(renderMenu)}</div>;
};

export default SubMenu_;
