import React from "react";
import MainPensum from "./MainPensum";

const Pensum = ({ carrera = "", pensum = "" }) => {
  return !carrera && !pensum ? (
    <span>Pensum</span>
  ) : (
    <MainPensum carrera={carrera} pensum={pensum} />
  );
};

export default Pensum;
