import React from 'react';
import './footer.css';

const Footer = () => {

    const year = (new Date().getFullYear());

    return (
        <footer>
            <div id='footer_fix'>
                <div className='footerBox'>
                    <span className='title'>Contacto</span>
                    <div className="contacto">
                        <span><b>PBX:</b> (+57) 6075690088&</span>
                        <span><b>Línea gratuita nacional:</b> 018000121022</span>
                        <span><b>Ventanilla Única:</b> Ext. 516</span>
                        <span><b>Atención al Ciudadano:</b> Ext. 517</span>
                        <span><b>Atención PQRSDF:</b> Ext. 506</span>
                        <span><b>Conmutador:</b> Ext. 603</span>
                        <span><b>Código Postal:</b> 546552</span>
                    </div>
                    <div className="atencion_ciudadano">
                        <p>
                            <b>Atención y servicios a la ciudadanía:</b>
                            <a href="mailto:atencionalciudadano@ufpso.edu.co" title="atencionalciudadano@ufpso.edu.co">atencionalciudadano@ufpso.edu.co</a>
                            <b>Notificaciones judiciales:</b>
                            <a href="mailto:notificacionesjudiciales@ufpso.edu.co" title="notificacionesjudiciales@ufpso.edu.co">notificacionesjudiciales@ufpso.edu.co</a>
                        </p>
                    </div>

                </div>

                <div className='footerBox'>
                    <span className='title'>Dirección - SEDES</span>
                    <div className='direccion'>
                        <span><b>El Algodonal:</b> Vía Acolsure, Ocaña (N.S) Colombia.</span>
                        <span><b>La Primavera:</b> Av. Francisco Fernández de Contreras, Ocaña (N.S) Colombia.</span>
                        <span><b>Escuela de Artes:</b> Calle 10 No 13-64, Ocaña (N.S) Colombia.</span>
                        <span><b>La Troya:</b> Corregimiento de los Ángeles, Municipio de Río de oro (Cesar).</span>
                        <span><b>Pueblo Nuevo:</b> Corregimiento, Ocaña (N.S) Colombia.</span>
                    </div>

                    <div className='horario'>
                        <span><b>Horario de atención:</b></span>
                        <span>Lunes a viernes de 7:00 a.m. a 12:00 m y de 2:00 p.m. a 5:00 p.m.</span>
                    </div>

                    <div className='politicas'>
                        <span><a href="/politicas_lineamientos" target="_blank" title="Políticas y lineamientos">Políticas y lineamientos</a></span>
                        <span><a href="/mapa_del_sitio" target="_blank" title="Mapa del sitio" alt="Mapa del sitio">Mapa del sitio</a></span>
                    </div>
                </div>
                <div className='footerBox'>
                    <picture className="logo_gob">
                        <a href="https://www.gov.co/" target="_blank" title="https://www.gov.co/">
                            <img src="/media/general/logos/logo_gov.png" className="img-responsive" />
                        </a>
                    </picture>

                    <div className="logos_aside">
                        <picture className="logo_co">
                            <a href="https://www.colombia.co/" target="_blank" title="https://www.colombia.co/">
                                <img src="/media/general/logos/logo_CO.png" className="img-responsive" />
                            </a>
                        </picture>
                        <picture className="logo_icontec">
                            <a href="https://ufpso.edu.co/ftp/pdf/meci-calidad/2021/CERTIFICADO%20ICONTEC.pdf" title="Certificado IQNET and ICONTEC" target="_blank">
                                <img src="/media/general/logos/Logo_ICONTEC.png" className="logo_ICONTEC" />
                            </a>
                        </picture>
                        <picture className="logo_iqnet">
                            <a href="https://ufpso.edu.co/ftp/pdf/meci-calidad/2021/CERTIFICADO IQNET.pdf" title="Certificado IQNET" target="_blank">
                                <img src="/media/general/logos/Logo_iqnet.png" className="logo_iqnet" />
                            </a>
                        </picture>
                    </div>
                    <div className="social_media">
                        <ul>
                            <li><a href='https://www.facebook.com/UFPSO' className="btn-facebook"><img src="/media/general/social/facebook_1.png" /></a></li>
                            <li><a href='https://www.instagram.com/ufpsocana_/' className="btn-instagram"><img src="/media/general/social/instagram_1.png" /></a></li>
                            <li><a href='https://www.youtube.com/user/ufpsocana' className="btn-youtube"><img src="/media/general/social/youtube_1.png" /></a></li>
                            <li><a href='https://twitter.com/ufpsocana' className="btn-twitter"><img src="/media/general/social/gorjeo.png" /></a></li>
                            <li><a href='https://ufpso.edu.co/Rss' className="btn-ufpso"><img src="/media/general/social/rss.png" /></a></li>
                        </ul>
                    </div>
                </div>

                <div className='footerBigBox'>
                    <div className="logo_ufpso">
                        <picture className="logo">
                            <a href="https://ufpso.edu.co/" target="_blank">
                                <img src="/media/general/logos/newsvg.png" />
                            </a>
                        </picture>
                        <picture className="escudo">
                            <a href="https://idm.presidencia.gov.co/deinteres/index.html" target="_blank">
                                <img src="/media/general/logos/escudo_colombia.png" />
                            </a>
                        </picture>
                    </div>
                    <span className="copyright">Universidad Francisco de Paula Santander - Ocaña</span>
                    <span className="minister">Institución de Educación Superior sujeta a inspección y vigilancia por el Ministerio de Educación Nacional</span>
                    <span className="year">© {year}</span>
                </div>

                <div className='footer_img'></div>
                <div className='footer_back'></div>
            </div>
        </footer>
    );
};
export default Footer;
