import React,{ useRef } from "react";
import "../componentes/styles/search.css"


function Search({ onChange,value,title }) {
      
    return (
       <>
        {/* Barra de búsqueda */}
        <div className="search-bar">
            <span className="material-icons search">search</span>
            <input 
                type="text" 
                placeholder={title} 
                value={value} 
                onChange={onChange} // Actualiza el término de búsqueda
            />
        </div>
      </>
    );
  }

  export default Search;