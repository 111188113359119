import React, { useEffect, useState } from "react";
import Dynamic from "../../../admin/contenido/dynamic_content";
import SubMenu_ from "../../submenu/subMenu";
import "./styles/body_content.css";

const BodyContent = ({ dataContent, submenu = [] }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Dinamico = [];

  dataContent.map((sec) => {
    Dinamico.push({ name: Dynamic[sec.name], data: sec });
  });

  const handlePaste = (e) => {
    e.preventDefault();
  };

  return (
    <div className="info_content bodyContainer" id="estructura">
      {submenu.length > 0 && <SubMenu_ submenu={submenu} />}

      <div
        style={{
          width: submenu.length > 0 && width > 1000 ? "82.2%" : "100%",
          marginLeft: submenu.length > 0 && width > 1000 ? 30 : 0,
        }}
      >
        {Dinamico.map((Box, index) => (
          <div
            key={index}
            id={Box.data.id}
            data-name={Box.data.name}
            className={"edit_component"}
            onPaste={handlePaste}
            contentEditable={false}
          >
            {Box.data.name === "Pensum" ? (
              <Box.name
                id={Box.data.id}
                carrera={Box.data.data.carrera}
                pensum={Box.data.data.pensum}
              />
            ) : (
              <Box.name id={Box.data.id} contentHTML={Box.data.data} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default BodyContent;
