import React from "react";
import {
  Link,
} from "react-router-dom";
import "./styles/top_back.css";

const TopBack = ({ dataTopBack, backTo, backName }) => {
  return (
    <section className="slide" data-s="titulo">
      <div className="content_img">
        <picture>
          <img
            src={
              dataTopBack.img
                ? (dataTopBack.categoria === "noticias"
                    ? "/media/contenido/cabezera/noticias/"
                    : dataTopBack.categoria === "eventos"
                    ? "media/contenido/cabezera/eventos/"
                    : "media/contenido/cabezera/") + dataTopBack.img
                : ""
            }
            style={{
              objectPosition: "center " + dataTopBack.img_pos + "%",
              display: dataTopBack.img ? "block" : "none",
            }}
          />
          {!dataTopBack.img ? (
            <span className="material-icons">broken_image</span>
          ) : null}
        </picture>
      </div>

      <div className="content_txt">
        {/* Ocultar el título solo para noticias */}
        {dataTopBack.categoria !== "noticias" && (
          <span className={"titled "}>{dataTopBack.titulo}</span>
        )}

        <div className="content-metainfo">
          <span className="dir-route">
            <Link to={backTo}>
              {backName}
              <span
                className="material-icons"
                style={{
                  transform: "scaleX(-1) translateY(2px) rotate(0deg)",
                  fontSize: "0.75rem",
                  marginLeft: "0.5rem",
                  color: "#c9c9c9",
                }}
              >
                shortcut
              </span>
            </Link>
          </span>

          <div className="public_info">
            <span>
              {dataTopBack.publicado
                ? "Publicado: " + dataTopBack.publicado
                : "No ha sido publicado"}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBack;
