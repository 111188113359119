import TopBack from './TopBack';
import HistoricoNoticias from './HistoricoNoticias';
import BodyContent from './BodyContent';
import Error404 from './Error404';
import Buscador from './Buscador';
import Servicios from './Servicios';



export {
    TopBack,
    HistoricoNoticias,
    BodyContent,
    Error404,
    Buscador,
    Servicios,
};
