import React from 'react';
import parse from 'html-react-parser';


const Subtitle = ({ contentHTML }) => {

  return (
    (contentHTML != "")
      ? parse(contentHTML)
      : <h2>Sub Título</h2>
  );
};

export default Subtitle;