import React from 'react';
import '../../../styles/loader.css';

const Loader = ({ color = '' }) => {
    return (
        <div className="loader">
            <div className={"sk-fading-circle " + color}>
                <div className="sk-circle1 sk-circle"></div>
                <div className="sk-circle2 sk-circle"></div>
                <div className="sk-circle3 sk-circle"></div>
                <div className="sk-circle4 sk-circle"></div>
                <div className="sk-circle5 sk-circle"></div>
                <div className="sk-circle6 sk-circle"></div>
                <div className="sk-circle7 sk-circle"></div>
                <div className="sk-circle8 sk-circle"></div>
                <div className="sk-circle9 sk-circle"></div>
                <div className="sk-circle10 sk-circle"></div>
                <div className="sk-circle11 sk-circle"></div>
                <div className="sk-circle12 sk-circle"></div>
            </div>
            {/* <span className={'text_load ' + color}>Cargando...</span> */}
        </div>
    );
}

export default Loader;

