import React, { useState, useEffect } from "react";


export default function Info() {

  useEffect(() => {



  }, []);



  return (
    <section className="slide" data-s="info">
      <div className="infoContainer">
        <div className="infoBox noCliped L" >
          <h1>Inscripciones Abiertas</h1>
          <h2>Pregrado II Semestre 2021</h2>
          <span>Preinscripción y pago de pines</span>
          <h3>22 de Abril al 29 de Mayo de 2021</h3>
          <span>Inscripción</span>
          <h3>23 de Abril al 31 de Mayo de 2021</h3>
        </div>
        <div className="infoBox cliped L">
          <picture>
            <img src="/media/contenido/cabezera/DSC_9578.jpg" />
          </picture>
        </div>
      </div>
    </section>
  );
}
