import React, { useState, useEffect } from "react";
import axios from 'axios';
import './flotante.css';

const Flotante = () => {

    const [dataFlotante, setFlotante] = useState([]);

    useEffect(() => {
        axios.post('/flotante/get').then((response) => {
             if (response.data.success) {
                setTimeout(function () {
                    setFlotante(response.data.data[0]);
                    document.body.classList.add('no-scroll');
                }, 1000);
            }
        }).catch((error) => { console.log(error); });
        return () => { setFlotante([]); };
    }, []);


    const close_float = () => {
        document.body.classList.remove('no-scroll');
        document.querySelector('.mainFlotante').classList.remove('activa');
    }

    const go_to = () => { 
        if(dataFlotante.URL !== "" && dataFlotante.URL !== null){
            window.open(dataFlotante.URL,"_blank")
        }
     }

    return (
        dataFlotante && <aside
            className={"mainFlotante" + ((dataFlotante.archivo) ? " activa" : "")}
            onClick={(e) => close_float(e)}>
            <button className="close_"
                onClick={(e) => close_float(e)}>
                <span className="material-icons">close</span>
            </button>
            <picture onClick={(e) => go_to(e)} style={{cursor:(dataFlotante.URL !== "" && dataFlotante.URL !== null)?"pointer":"default"}}>
                {dataFlotante.archivo && <img src={"/media/principal/flotante/" + dataFlotante.archivo} />}
            </picture>
        </aside>
    );
};
export default Flotante;
