import React, { useEffect } from 'react';
import parse from 'html-react-parser';


const Parag = ({ id, contentHTML }) => {


  return (
    (contentHTML != "")
      ? parse(contentHTML)
      : <p>Este es un ejemplo de un parrafo predeterminado. Siéntete libre de editarlo y personalizarlo según tus necesidades. Puedes usar <b>negrita</b> o <i>cursiva</i> si quieres, desde las herramientas de formato del editor en la barra superior.
        También puedes agregar imágenes, videos, enlaces, tablas, listas y mucho más para hacer el contenido más atractivo, simplemente arrastrándo el elemento que desees desde el panel izquierdo.</p>

  );
}
export default Parag;

