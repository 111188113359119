import React from "react";
import parse from 'html-react-parser';

const HighlightText = ({ contentHTML }) => {

  return (
    (!contentHTML)
      ? <div><p>[Ingrese el texto que desee destacar]</p></div>
      : parse(contentHTML)
  );
};
export default HighlightText;