import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MainLoader from "./loader/MainLoader";
import Menu from "./menu/Menu";
import Servicios from "./contenido/fixed/Servicios";
import Aside from "./contenido/fixed/Aside";
import Flotante from "./flotante/Flotante";
import Cabezera from "./header/Header";
import Footer from "./footer/Footer";
import Content from "./contenido/Contenido";
import Home from "./principal/Principal";
import UpButton from "./contenido/fixed/UpButton";
import Directorio from "./contenido/fixed/Directorio";
import NoticiasCompletas from "./principal/componentes/NoticiasCompletas";  // Importa el nuevo componente de todas las noticias
import AllEvents from "./principal/componentes/AllEvents"; // Importa el nuevo componente de todos los eventos

import { Error404, Buscador } from "./contenido/fixed/Fixed";

import "../admin/contenido/dynamic_content/dynamic_content.css";
import "../../styles/animations.css";
import "../../styles/control.css";
import "../../styles/fonts.css";
import "../../styles/responsive.css";
import "../../styles/cabezera.css";


export const LoadingContext = createContext();

const Main = () => {
  const [AtHome, setAtHome] = useState("");
  const [headerClass, setHeaderClass] = useState([]);
  const [navClass, setNavClass] = useState("nav_show");
  const [scrollDir, setScrollDir] = useState("down");

  useEffect(() => {
    startLoading();
    //accesibilidad
    var doc = document.createElement("script");
    doc.setAttribute("data-account", "InJt1V9LcZ");
    doc.setAttribute("src", "https://cdn.userway.org/widget.js");
    document.body.appendChild(doc);
  }, []);

  //scroll-events
  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;
    let fixed_nav = document.getElementById("fixed_nav");

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;

      if (lastScrollY > 0) {
        if (fixed_nav.classList.contains("blacode")) {
          setHeaderClass([...headerClass, "scrolled", "blacode"]);
        } else {
          setHeaderClass([...headerClass, "scrolled", "black"]);
        }
        if (scrollDir == "down") {
          if (fixed_nav.classList.contains("blacode")) {
            setHeaderClass(["nav_off", "scrolled", "blacode"]);
            setNavClass("nav_hidden");
          } else {
            setHeaderClass(["nav_off", "scrolled", "black"]);
            setNavClass("nav_hidden");
          }
        } else if (scrollDir == "up") {
          if (fixed_nav.classList.contains("blacode")) {
            setHeaderClass(["nav_on", "scrolled", "blacode"]);
            setNavClass("nav_show");
          } else {
            setHeaderClass(["nav_on", "scrolled", "black"]);
            setNavClass("nav_show");
          }
        }
      } else {
        if (fixed_nav.classList.contains("blacode")) {
          setHeaderClass(["blacode"]);
        } else {
          setHeaderClass([]);
        }
      }

      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  //loading control
  const cancelLoading = () => {
    window.scrollTo(0, 0);
    // setTimeout(function () {
    document.querySelector("#main_loader").classList.add("hidden");
    document.querySelector("#logo_default").classList.add("animated");
    document.body.classList.remove("no-scroll");
    // }, 100);
  };

  const startLoading = () => {
    window.scrollTo(0, 0);
    document.querySelector("#main_loader").classList.remove("hidden");
    document.body.classList.add("no-scroll");
  };

  return (
    <LoadingContext.Provider value={{ startLoading, cancelLoading }}>
      <Router>
        <MainLoader />
        {AtHome && <Flotante />}
        <Cabezera headerClass={headerClass.join(" ")} navClass={navClass} />
        <Aside />
        <Menu />
        <main id="wrapper">
          <Routes>
            <Route path="/" element={<Home setAtHome={setAtHome} />} />
            <Route exact path="/404" element={<Error404 />} />
            <Route exact path="/buscador" element={<Buscador />} />
            <Route exact path="/servicios" element={<Servicios />} />
            <Route exact path="/directorio" element={<Directorio />} />
            <Route exact path="/:Link" element={<Content />} />
            <Route exact path="/historico_noticia" element={<NoticiasCompletas />} />
            <Route exact path="/historico_eventos" element={<AllEvents />} />
            <Route exact path="*" element={<Error404 />} />
          
          </Routes>
        </main>
      </Router>
      <Footer />
      <UpButton />
    </LoadingContext.Provider>
  );
};
export default Main;
