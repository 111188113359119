import React,{ useState } from "react"

const Accordeon = ({button, children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const hadleClick=()=>{
        setIsOpen(!isOpen);
    }
    return (
        <>
        <div onClick={hadleClick}>
            {button}
        </div>
        <div style={{display: isOpen?"block":"none"}}>
            {children}
        </div>
        </>
    )
}

export default Accordeon;