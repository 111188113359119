import React, { useEffect, useRef } from 'react';
import Section from './Animaciones';

export default function Quote() {
    const quoteRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const quoteBox = quoteRef.current;
            const rect = quoteBox.getBoundingClientRect();
            const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
            
            if (isVisible) {
                quoteBox.classList.add('visible');
                quoteBox.classList.remove('hidden');
            } else {
                quoteBox.classList.add('hidden');
                quoteBox.classList.remove('visible');
            }
        };

        // Agregar el listener al evento de scroll
        window.addEventListener('scroll', handleScroll);

        // Limpiar el listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Section className="slide" data-s="quote">
            
            <div className="quote_box hidden" ref={quoteRef}>
                <picture className="quote_img">
                    <img src="media/general/director/director.jpg" alt="Director" />
                </picture>

                <div className="quote_txt">
                    <p>Mi compromiso con la acreditación de alta calidad, es seguir aportando en la construcción de una institución que responda a las necesidades de la región y del país.</p>
                    <p><i>- Magíster Édgar Antonio Sánchez Ortiz</i></p>
                </div>
            </div>
        </Section>
    );
}