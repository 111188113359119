import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../../admin/template/Loader';
import './styles/AllEvents.css';
import { LoadingContext } from '../../Main';
import Section from "./Animaciones";
import Search from "./search";

const EventosCompletos = () => {
  const { cancelLoading } = useContext(LoadingContext);
  const [eventos, setEventos] = useState([]);
  const [activeEvent, setActiveEvent] = useState(null);

  // Estado para la paginación
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [eventsPerPage] = useState(10); // Eventos por página

  // Estado para el término de búsqueda
  const [searchTerm, setSearchTerm] = useState(''); // Término de búsqueda

  useEffect(() => {
    var formData = new FormData();
    formData.append("filter", "3"); // Asumo que el filtro para eventos es "3"

    axios.post('/contenido/bycat', formData).then((response) => {
      if (response.data.success) {
        const sortedEventos = response.data.data.sort((a, b) => 
          new Date(b.publicado) - new Date(a.publicado) // Ordenar por fecha descendente
        );
        cancelLoading();
        setEventos(sortedEventos);
      }
    });
  }, []);

  // Lógica de filtrado por búsqueda
  const filteredEvents = eventos.filter((event) => 
    event.titulo.toLowerCase().includes(searchTerm.toLowerCase()) || 
    event.resumen.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Lógica de paginación
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent); // Eventos de la página actual filtrados por búsqueda

  // Cambiar página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch =(e)=>{
    setSearchTerm(e.target.value);
  }

  return (
    <>
      <section class="slide" data-s="titulo">
        <div class="content_img">
          <picture>
          <img class="img_news_all" src="https://new.ufpso.edu.co/media/contenido/cabezera/casona.jpg"  alt="header"/>
          </picture>
        </div>
        <div class="content_txt">
          <span class="titled ">
            Eventos
          </span><div class="content-metainfo">
          <span class="dir-route">
            <a href="/">Inicio
              <span class="material-icons">
                shortcut
              </span>
            </a>
          </span>
        </div>
      </div>
      </section>
      <Section className="all-events-section">
        {/* Barra de búsqueda */}
        <Search onChange={handleSearch} value={searchTerm} title={"Buscar"}/>

        {filteredEvents.length !== 0 ? (
          <div className="events-list">
            {currentEvents.map((box, i) => (
              <div 
                key={i} 
                style={{ display: activeEvent !== i ? "block" : "none" }} 
                className="box_event_list_card_all"
              >
                <Link to={`/${box.nav.split('/').pop()}`}>
                  <div className="box_event_list_card_main_container">
                    <div className="box_event_list_card_img_container">
                      <img 
                        src={"/media/contenido/cabezera/eventos/" + (box.img || "default_event_image.jpg")} 
                        className="imgList" 
                        alt={box.titulo}
                      />
                    </div>
                    <div style={{ width: "60%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                      <h4 className="title">{box.titulo}</h4>
                      <h6 style={{ margin: "0", marginBottom: "5px" }}>
                        {new Date(box.publicado).toLocaleDateString()}
                      </h6>
                      <p className="resume">{box.resumen}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

            {/* Paginación */}
            <div className="pagination">
              {/* Botón de página anterior */}
              <button 
                onClick={() => paginate(currentPage - 1)} 
                disabled={currentPage === 1}
              >
                Anterior
              </button>

              {/* Botón de página siguiente */}
              <button 
                onClick={() => paginate(currentPage + 1)} 
                disabled={indexOfLastEvent >= filteredEvents.length}
              >
                Siguiente
              </button>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </Section>
    </>
  );
};

export default EventosCompletos;
