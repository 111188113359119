import React, { useState, useEffect, useContext } from "react";

import { menuToggle } from "../menu/menuToggle.js";
import { motion } from "framer-motion";

const Cabezera = ({ headerClass, navClass }) => {
  function menuClose() {
    var nav = document.querySelector("nav");
    if (nav.className.split(" ").indexOf("active") >= 0) {
      menuToggle();
    }
  }

  return (
    <nav id="cabezera" className={navClass}>
      <div id="fixed_nav" className={headerClass}>
        <div id="logo_default" onClick={menuClose}>
          <a href="https://new.ufpso.edu.co">
            <picture>
              <img
                src="/media/general/logos/logoHorizontal_blank.png"
                alt="Logo UFPSO"
                
              />
            </picture>
          </a>
        </div>
        <div id="main-menu">
          <motion.div id="govco-btn"  whileHover={{ scale: 1.1 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}>
            <a href="https://www.gov.co">
            <picture>
              <img
                src="/media/general/logos/logoGov.png"
                alt="Logo GOV.CO"
                className="logoGov"
              />
            </picture>
            </a>
          </motion.div>

          {/*<span id="traductor" className="material-icons"  onClick={menuTraductor}>language</span>*/}
          <div id="hamburger" onClick={() => menuToggle()} title="Menú">
            <div className="hamburger-box">
              <div className="hamburger-inner"></div>
              <div className="hamburger-inner"></div>
              <div className="hamburger-inner"></div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Cabezera;
