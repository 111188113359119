import React, { useState } from 'react';
import parse from 'html-react-parser';


const Title = ({ contentHTML }) => {


    return (
        (contentHTML != "")
            ? parse(contentHTML)
            : <h1>Título</h1>
    );
};

export default Title;
