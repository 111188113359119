import React from "react";
import { useNavigate } from "react-router-dom";
import "./submenu.css";

const ButtonMenu = ({ name, href, type }) => {
  const navigation = useNavigate();
  const onClick = () => {
    type === 1 ? navigation(`/${href}`) : window.open(href);
    console.log(href);
  };
  return (
    <div className="szh-menu-button" onClick={onClick}>
      <h4 className="noMargin text">{name}</h4>
    </div>
  );
};

export default ButtonMenu;
