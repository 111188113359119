let Dynamic = {};

Dynamic["Title"] = require("./title").default;
Dynamic["Subtitle"] = require("./subtitle").default;
Dynamic["Parag"] = require("./parag").default;
Dynamic["Image"] = require("./image").default;
Dynamic["Audio"] = require("./audio").default;
Dynamic["HighlightText"] = require("./highlight").default;
Dynamic["Table"] = require("./table").default;
Dynamic["List"] = require("./list").default;
Dynamic["Accordion"] = require("./accordion_base").default;
Dynamic["Iframe"] = require("./iframe").default;
Dynamic["File"] = require("./file").default;
Dynamic["Link"] = require("./link").default;
Dynamic["Hr"] = require("./hr").default;
Dynamic["Pensum"] = require("./pensum").default;

export default Dynamic;
