import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../../admin/template/Loader';
import './styles/NoticiasCompletas.css';
import { LoadingContext } from '../../Main';
import Section from "./Animaciones";
import Search from "./search";

const NoticiasCompletas = () => {
const { cancelLoading } = useContext(LoadingContext);
  const [Noticias, setNoticias] = useState([]);
  const [activeNew, setActiveNew] = useState(null);
  
  // Estado para la paginación
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [newsPerPage] = useState(10); // Noticias por página
  
  // Estado para el término de búsqueda
  const [searchTerm, setSearchTerm] = useState(''); // Estado para almacenar el término de búsqueda

  useEffect(() => {
    var formData = new FormData();
    formData.append("filter", "2");

    axios.post('/contenido/bycat', formData).then((response) => {
      if (response.data.success) {
        const sortedNoticias = response.data.data.sort((a, b) => 
          new Date(b.publicado) - new Date(a.publicado)
        );
        cancelLoading();
        setNoticias(sortedNoticias);
      }
    });
/*
    return () => {
      setNoticias([]);
    };*/
  }, []);

  // Lógica de filtrado por búsqueda
  const filteredNews = Noticias.filter((news) => 
    news.titulo.toLowerCase().includes(searchTerm.toLowerCase()) || 
    news.resumen.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch =(e)=>{
    setSearchTerm(e.target.value);
  }

  // Lógica de paginación
  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = filteredNews.slice(indexOfFirstNews, indexOfLastNews); // Noticias de la página actual filtradas por búsqueda

  // Cambiar página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
//console.log(filteredNews.length);
  return (
    <>

    <section class="slide" data-s="titulo">
      <div class="content_img">
        <picture>
        <img class="img_news_all" src="https://new.ufpso.edu.co/media/contenido/cabezera/casona.jpg"  alt="header"/>
        </picture>
      </div>
      <div class="content_txt">
        <span class="titled ">
          Noticias
        </span><div class="content-metainfo">
        <span class="dir-route">
          <a href="/">Inicio
            <span class="material-icons" >
              shortcut
            </span>
          </a>
        </span>
      </div>
    </div>
    </section>
    <Section className="all-news-section">
      <Search onChange={handleSearch} value={searchTerm} title={"Buscar"}/>
      {filteredNews.length !== 0 ? (
        <div className="news-list">
          {currentNews.map((box, i) => (
            <div 
              key={i} 
              style={{ display: activeNew !== i ? "block" : "none" }} 
              className="box_news_list_card_all"
            >
              <Link to={`/${box.nav.split('/').pop()}`}>
                <div className="box_news_list_card_main_container">
                  <div className="box_news_list_card_img_container">
                    <img 
                      src={"/media/contenido/cabezera/noticias/" + (box.img || "DSC_9905.jpg")} 
                      className="imgList" 
                      alt={box.titulo}
                    />
                  </div>
                  <div style={{ width: "60%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <h4 className="title">{box.titulo}</h4>
                    <h6 style={{ margin: "0", marginBottom: "5px" }}>
                      {new Date(box.publicado).toLocaleDateString()}
                    </h6>
                    <p className="resume">{box.resumen}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}

          {/* Paginación */}
          <div className="pagination">
            {/* Botón de página anterior */}
            <button 
              onClick={() => paginate(currentPage - 1)} 
              disabled={currentPage === 1}
            >
              Anterior
            </button>

            {/* Botón de página siguiente */}
            <button 
              onClick={() => paginate(currentPage + 1)} 
              disabled={indexOfLastNews >= filteredNews.length}
            >
              Siguiente
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Section>
    </>
  );
};

export default NoticiasCompletas;
