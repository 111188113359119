import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../../template/Loader";
import "./pensum.css";

const MainPensum = ({ carrera = "", pensum = "" }) => {
  const [data, setData] = useState([]);
  const [parsedData, setParsedData] = useState([]);

  const agregarRequisitos = () => {
    const newData = { ...data };

    Object.values(newData).map((semestre) => {
      semestre.map((materia) => {
        materia.REQUISITOS = materia.REQUISITOS || [];
        materia.SIGUIENTE.map((siguiente) => {
          Object.values(newData).map((semestreNuevo) => {
            semestreNuevo.map((materiaSiguiente) => {
              find = materiaSiguiente.MATERIA === siguiente.CODIGO;
              if (find) {
                materiaSiguiente.REQUISITOS = materiaSiguiente.REQUISITOS || [];
                materiaSiguiente.REQUISITOS.push({
                  MATERIA: materia.MATERIA,
                  NOM_MATERIA: materia.NOM_MATERIA,
                });
              }
            });
          });
        });
      });
    });
    console.log(newData);
    setParsedData(newData);
  };

  useEffect(() => {
    axios
      .get(
        `https://gestionacademica.ufpso.edu.co/pensum?carrera=${carrera}&pensum=${pensum}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    agregarRequisitos();
  }, [data]);
  return (
    <div>
      <div className="containerPensum">
        {Object.keys(parsedData).length > 0 ? (
          Object.values(parsedData).map((semestre, index) => (
            <>
              <div className="containerSemestre">Semestre {index + 1}</div>

              <div className="containerTable">
                <table>
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Nombre</th>
                      <th>HT</th>
                      <th>HP</th>
                      <th>Crédito</th>
                      <th>Requisitos</th>
                      <th>Simultanea</th>
                      <th>Créditos</th>
                      <th>Tipo</th>
                      <th>Equivalente</th>
                    </tr>
                  </thead>
                  <tbody>
                    {semestre.map((materia) => (
                      <tr>
                        <td>{materia.MATERIA}</td>
                        <td>{materia.NOM_MATERIA}</td>
                        <td>{materia.HT}</td>
                        <td>{materia.HP}</td>
                        <td>{materia.CR}</td>
                        <td>
                          {materia.REQUISITOS.length > 0
                            ? materia.REQUISITOS.map((req) => (
                                <div
                                  title={req.NOM_MATERIA}
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {req.MATERIA}
                                  <br />
                                </div>
                              ))
                            : "-"}
                        </td>
                        <td>
                          {materia.SIMULTANEA.length > 0
                            ? materia.SIMULTANEA.map((sim) => (
                                <>
                                  {sim.MATERIA}
                                  <br />
                                </>
                              ))
                            : "-"}
                        </td>
                        <td>
                          {materia.REQ_CREDITO.length > 0
                            ? materia.REQ_CREDITO[0].CREDITOS
                            : "-"}
                        </td>
                        <td>{materia.TIPO_ELECTIVA === "S" ? "ELECT" : "-"}</td>
                        <td>
                          {materia.EQUIVALENTE.map((eq) => (
                            <>
                              {eq.MATERIA}
                              <br />
                            </>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ))
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default MainPensum;
