import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const UpButton = ()=>{
  const [scrollY, setScrollY] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
    }); 
  }; 
  return(
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: scrollY >= windowHeight ? 1 : 0 }}
      transition={{ duration: 0.6 }}
      style={{
        position: "fixed",
        bottom: 13,
        right: 18,
        borderRadius: '50%',
        backgroundColor: '#c71122',
        border: 'solid 0px',
        color: "white",
        width: 44,
        height: 44,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 20,
        cursor: 'pointer'
      }}
      onClick={scrollToTop}
    >
      <div style={{
        
        borderRadius: '50%',
        backgroundColor: '#c71122',
        border: 'solid 1.5px',
        borderColor: 'white',
        color: "white",
        width: 33,
        height: 33,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
        }}
      >
        <motion.span 
        className="material-icons arrow_circle_up" 
        whileHover={{ scale: 1.5 }}
        whileTap={{ scale: 0.9 }} 
      >
        expand_less
      </motion.span>
      </div>     
    </motion.div>
  )
}

export default UpButton;