import React from 'react';
import parse from 'html-react-parser';


const List = ({ contentHTML }) => {

  return (
    (!contentHTML)
      ? <ul><li>Ingrese el contenido de la lista</li></ul>
      : parse(contentHTML)
  );
}

export default List;

