import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loader from "../../../admin/template/Loader";
import axios from 'axios';
import Section from "./Animaciones";
import { motion } from "framer-motion"; // Importar motion para animaciones

const Events = () => {
  const [Eventos, setEventos] = useState([]);
  const [activeEvent, setActiveEvent] = useState(0); // Índice del evento activo
  const [mostrar, setMostrar] = useState(true); // Controla la animación de fade
  const navigate = useNavigate();

  // Función para obtener los eventos
  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const formData = new FormData();
        formData.append("filter", "3");
        const response = await axios.post('/contenido/bycat', formData);
        if (response.data.success) {
          const sortedEventos = response.data.data.sort((a, b) => 
            new Date(b.publicado) - new Date(a.publicado) // Ordenar por fecha descendente
          );
          setEventos(sortedEventos.slice(0, 5)); // Filtrar solo los 5 más recientes
        }
      } catch (error) {
        console.error("Error fetching events", error);
      }
    };
    fetchEventos();
    return () => setEventos([]); // Limpieza
  }, []);

  // Función para avanzar al siguiente evento
  const handleForward = () => {
    if (activeEvent === Eventos.length - 1) {
      handleView(0); // Si es el último, vuelve al primero
    } else {
      handleView(activeEvent + 1); // Avanza al siguiente evento
    }
  };

  // Función para retroceder al evento anterior
  const handleBack = () => {
    if (activeEvent === 0) {
      handleView(Eventos.length - 1); // Si es el primero, vuelve al último
    } else {
      handleView(activeEvent - 1); // Retrocede al evento anterior
    }
  };

  // Función para cambiar la vista del evento
  const handleView = (i) => {
    if (i === activeEvent) return; // Si es el mismo evento, no hace nada
    setMostrar(false); // Inicia fade-out
    setTimeout(() => {
      setActiveEvent(i); // Cambia el evento activo
      setMostrar(true); // Inicia fade-in
    }, 300); // Tiempo de la animación
  };

  return (
    <Section className="slide" data-s="events">


      <div className="slide_arrow left noselect" onClick={handleBack}>
          <span className="material-icons">arrow_back_ios</span>
      </div>

      {/* Botón para avanzar */}
      <div className="slide_arrow right noselect" onClick={handleForward}>
          <span className="material-icons">arrow_forward_ios</span>
      </div>

      <div className="slide_title"><a href="/historico_eventos">Eventos</a></div>

      <div className="carousel_container">
        {/* Botón para retroceder */}
        

        <div className="carousel">
          {Eventos.length > 0 ? 
            <motion.div 
              initial={{ opacity: 1 }}
              animate={{ opacity: mostrar ? 1 : 0 }} // Animación de fade
              transition={{ duration: 0.3 }} // Duración de la animación
              className="sub_event"
              onClick={() => window.location.href=Eventos[activeEvent].nav}
            >
              <div className="img_event">
                <picture>
                  <img src={`/media/contenido/cabezera/eventos/${Eventos[activeEvent].img || "DSC_9905.jpg"}`} alt="Evento" />
                </picture>
              </div>
              <div className="txt_event">
                <div className="txt_event_title" title={Eventos[activeEvent].titulo}>{Eventos[activeEvent].titulo}</div>
                <div className="txt_event_resume" title={Eventos[activeEvent].resumen}>{Eventos[activeEvent].resumen}</div>
                <div className="txt_event_date">{Eventos[activeEvent].publicado}{Eventos[activeEvent].editado ? " | Editado" : ""}</div>
              </div>
            </motion.div>
            : <Loader />}
        </div>

        
      </div>
      <div className="view_all_button">
        <a href="/historico_eventos">Ver todos</a>
      </div>

    </Section>
  );
};

export default Events;
