import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Loader from "../../../admin/template/Loader";
import axios from 'axios';
import Section from "./Animaciones";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";


const News = () => {
  const [clicked, clickState] = useState(false);
  const [Noticias, setNoticias] = useState([]);
  const [scrollDirection, setDirection] = useState("");
  const [currentPosition, setCurrentPosition] = useState(0);
  const [activeNew, setActiveNew] = useState(0);
  const [mostrar, setMostrar] = useState(true);
  const [windowHeight, setWindowHeight]= useState(window.innerHeight);

  useEffect(()=>{
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[])
  
  const scrollContainerRef = useRef(null);

  const itemStyle = {
    transition: 'transform 0.3s ease-out',
    transform: `translateX(-${currentPosition}px)`
  };

  useEffect(() => {
    var formData = new FormData();
    formData.append("filter", "2");
    axios.post('/contenido/bycat', formData).then((response) => {
      if (response.data.success) {
        const sortedNoticias = response.data.data.sort((a, b) => 
          new Date(b.publicado) - new Date(a.publicado) // Ordenar por fecha y hora descendente (de más reciente a más antigua)
        );
        setNoticias(sortedNoticias);
        setActiveNew(0); // La primera noticia será la más reciente
      }
    });
    return () => {
      setNoticias([]);
    };
  }, []);


  useEffect(() => {
    if ((Noticias.length > 0) && clicked) {

      let scrollContainer = scrollContainerRef.current;
      let itemWidth = scrollContainer.firstElementChild.offsetWidth;
      let computedStyle = getComputedStyle(scrollContainer.firstElementChild);
      let marginRight = parseInt(computedStyle.marginRight);

      if (scrollDirection === 'right') {
        if (currentPosition < scrollContainer.offsetWidth - itemWidth * 2) {
          setCurrentPosition(currentPosition + itemWidth + marginRight);
        }
      } else if (scrollDirection === 'left') {
        if (currentPosition - itemWidth < 0) {
          setCurrentPosition(0);
        } else {
          setCurrentPosition(currentPosition - itemWidth - marginRight);
        }
      }
      clickState(false);

    }
  }, [clicked]);

  const scrollTO = () => {
    window.scroll({
      top: windowHeight,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleForward = () => {
    if (activeNew === Noticias.length - 1) {
      handleView(0)
    } else {
      handleView(activeNew + 1)
    }
    return;
  }

  const handleBack = () => {
    if (activeNew === 0) {
      handleView(Noticias.length - 1)
    } else {
      handleView(activeNew - 1)
    }
  }

  const handleView = (i) => {
    if (i === activeNew) {
      return;
    }
    setMostrar(false)
    setTimeout(() => {
      setActiveNew(i);
    }, 100)
    
    setTimeout(() => {
      setMostrar(true);
    }, 300)
  }

  return (
    <Section className="slide" data-s="noticias">
      <div className="slide_title"><a href="/historico_noticia">Noticias</a></div>
      <div className="slide_arrow left noselect" onClick={handleBack}>
        <span className="material-icons">arrow_back_ios</span>
      </div>
      <div className="slide_arrow right noselect" onClick={handleForward}>
        <span className="material-icons">arrow_forward_ios</span>
      </div>

      {(Noticias.length) ?
        <div className="news_container">
          <div className="box_news_container">
            <motion.div 
              initial={{ opacity: 1 }}
              animate={{ opacity: mostrar ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              className="box_news"
            >
              <a  href={Noticias[activeNew].nav}>
                <div className="box_media">
                  <picture><img src={"/media/contenido/cabezera/noticias/" + (Noticias[activeNew].img || "DSC_9905.jpg")} /></picture>
                </div>
                <div className="box_content">
                  <h3 className="title" title={Noticias[activeNew].titulo}>{Noticias[activeNew].titulo}</h3>
                  <h4 className="resume" title={Noticias[activeNew].resumen}>{Noticias[activeNew].resumen}</h4>
                  {/* Mostrar la fecha de publicación debajo del resumen */}
                  <p className="date" style={{ color: "#888" }}>{Noticias[activeNew].publicado}</p>
                  
                
                </div>
                
              </a>
              <div className="ver-todas-container">
                  <a href="/historico_noticia" className="ver-todas-btn">
                    Ver todas
                  </a>
                </div>
            </motion.div>
          </div>

          <div className="box_news_list">
            {/* Limitar la visualización a solo 10 noticias */}
            {Noticias.slice(0, 10).map((box, i) => (
              <motion.div key={i} initial={{ x: activeNew !== i ? 0 : -100 }}
                animate={{ x: activeNew !== i ? 0 : -100,
                  opacity: activeNew !== i ? 1 : 0,
                  display: activeNew !== i  ? "block" : "none", 
                  height: activeNew !== i ? "180px" : "0px"
                }}
                transition={{ duration: 0.5 }}
                style={{
                  cursor: "pointer", 
                }}
                onTap={() => { scrollTO(); handleView(i) }}
              >
                <div style={{ display: activeNew !== i ? "block" : "none" }} className="box_news_list_card">
                  <div className="box_news_list_card_main_container">
                    <div className="box_news_list_card_img_container"> 
                      <img src={"/media/contenido/cabezera/noticias/" + (box.img || "DSC_9905.jpg")} className="imgList" />
                    </div>
                    <div style={{ width: "60%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                      <h4 className="title">{box.titulo}</h4>
                      <h6 style={{ margin: "0", marginBottom: "5px" }}>{box.publicado}</h6>
                      <p className="resume">{box.resumen}</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
        : <Loader />}
    </Section>
  );
};

export default News;
