import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import { menuToggle } from "./menuToggle.js";
import Loader from "../../admin/template/Loader";
import axios from "axios";
import "./menu.css";
import { motion } from "framer-motion";
import Accordeon from "./Accordeon.jsx";

const Menu = () => {
  const [dataMenu, setMenu] = useState([]);
  const [backImg, setBackImg] = useState({
    backgroundImage:
      'url("https://webufpso.ufpso.edu.co/media/contenido/cabezera/DSC_9606.jpg")',
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    axios.post("/menu/items").then((response) => {
      if (response.data.success) {
        /**Asignamos los datos */
        const newmenu = response.data.data;
        newmenu.map((boxchild, indexi) => {
          if (boxchild.subs != undefined) {
            let newChilds = [];
            boxchild.subs.map((boxchildi, index) => {
              const person = newChilds.find((p) => p.grupo === boxchildi.grupo);
              if (person) {
                person.childos.push(boxchildi);
              } else {
                newChilds.push({
                  id: "g" + indexi + "-" + index,
                  grupo: boxchildi.grupo,
                  childos: [boxchildi],
                });
              }
            });
            boxchild.subs = newChilds;
          }
          return boxchild;
        });

        setMenu(newmenu);
      } else {
        alert("Error 5858");
      }
    });
  }, []);

  function subNav(event, box) {
    if (typeof box.subs == "undefined") {
      window.location.href = box.nav;
    } else {
      var nav = document.querySelectorAll(".subNav");
      var obj = event.currentTarget;
      var oth = document.querySelectorAll(".topNav li");
      var data = obj.getAttribute("data-item");

      oth.forEach((item) => {
        if (item != obj) {
          item.classList.add("not-active");
          item.classList.remove("active");
        } else {
          item.classList.add("active");
          item.classList.remove("not-active");
        }
      });

      nav.forEach((item) => {
        item.classList.remove("active");
      });
      document.getElementById(data).classList.add("active");
    }
  }

  function isLink(str) {
    // Regular expression to match a URL
    var urlPattern =
      /^(https?:\/\/)?([\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/;

    // Use the test() method to check if the string matches the URL pattern
    return urlPattern.test(str);
  }

  return (
    <>
      {windowWidth > 680 ? (
        <aside id="menu">
          {!dataMenu.length ? <Loader color="white" /> : null}
          <div className="background_img" style={backImg}></div>
          <div className="aside-section aside-left">
            <ul className="topNav">
              {dataMenu.length
                ? dataMenu
                    .sort((a, b) => a.orden - b.orden)
                    .map((box) =>
                      box.visible != "0" ? (
                        <motion.li
                          whileHover={{ scale: 1.1 }}
                          transition={{
                            type: "spring",
                            stiffness: 400,
                            damping: 10,
                          }}
                          key={box.id}
                          data-item={box.id}
                          onClick={(e) => subNav(e, box)}
                        >
                          <span>{box.nombre}</span>
                        </motion.li>
                      ) : null
                    )
                : null}
            </ul>
          </div>

          <div className="aside-section aside-right">
            {dataMenu.length
              ? dataMenu.map((boxa) => (
                  <ul key={boxa.id} className="subNav" id={boxa.id}>
                    {boxa.subs
                      ? boxa.subs.map((elem, i) => (
                          <ul key={elem.id} className="sub2Nav">
                            <li key={"l" + elem.id} className="nav-header">
                              {elem.grupo}
                            </li>
                            {elem.childos
                              ? elem.childos
                                  .sort((a, b) => a.orden - b.orden)
                                  .map((bxx) =>
                                    bxx.visible != "0" ? (
                                      <Link
                                        key={bxx.id}
                                        to={
                                          !isLink(bxx.nav)
                                            ? "/" + bxx.nav
                                            : bxx.nav
                                        }
                                        target={isLink(bxx.nav) ? "_blank" : ""}
                                      >
                                        <li>
                                          <span
                                            title={bxx.nombre}
                                            onClick={menuToggle}
                                          >
                                            {bxx.nombre}
                                          </span>
                                        </li>
                                      </Link>
                                    ) : null
                                  )
                              : null}
                          </ul>
                        ))
                      : null}
                  </ul>
                ))
              : null}
          </div>
        </aside>
      ) : (
        <aside id="menu">
          {!dataMenu.length ? <Loader color="white" /> : null}
          <div className="background_img" style={backImg}></div>
          <div className="aside-section aside-left">
            <ul
              className="topNav"
              style={{
                width: "100%",
                height: "500px",
                display: "flex",
                overflow: "auto",
                flexDirection: "column",
              }}
            >
              {dataMenu.length
                ? dataMenu
                    .sort((a, b) => a.orden - b.orden)
                    .map((box) =>
                      box.visible != "0" ? (
                        <div>
                          <Accordeon
                            button={
                              <motion.li
                                whileHover={{ scale: 1.1 }}
                                transition={{
                                  type: "spring",
                                  stiffness: 400,
                                  damping: 10,
                                }}
                                key={box.id}
                                data-item={box.id}
                                onClick={(e) => subNav(e, box)}
                              >
                                <span>{box.nombre}</span>
                              </motion.li>
                            }
                          >
                            <ul
                              key={box.id}
                              id={box.id}
                              style={{ padding: "0px" }}
                            >
                              {box.subs
                                ? box.subs.map((elem, i) => (
                                    <ul key={elem.id} className="">
                                      <li
                                        key={"l" + elem.id}
                                        className="nav-header"
                                      >
                                        {elem.grupo}
                                      </li>
                                      {elem.childos
                                        ? elem.childos
                                            .sort((a, b) => a.orden - b.orden)
                                            .map((bxx) =>
                                              bxx.visible != "0" ? (
                                                <Link
                                                  key={bxx.id}
                                                  to={
                                                    !isLink(bxx.nav)
                                                      ? "/" + bxx.nav
                                                      : bxx.nav
                                                  }
                                                  target={
                                                    isLink(bxx.nav)
                                                      ? "_blank"
                                                      : ""
                                                  }
                                                >
                                                  <li>
                                                    <span
                                                      title={bxx.nombre}
                                                      onClick={menuToggle}
                                                    >
                                                      {bxx.nombre}
                                                    </span>
                                                  </li>
                                                </Link>
                                              ) : null
                                            )
                                        : null}
                                    </ul>
                                  ))
                                : null}
                            </ul>
                          </Accordeon>
                        </div>
                      ) : null
                    )
                : null}
            </ul>
          </div>
          <div className="aside-section aside-right"></div>
        </aside>
      )}
    </>
  );
};
export default Menu;
