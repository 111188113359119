import React from 'react';
import parse from 'html-react-parser';

const File = ({ contentHTML }) => {

    return (
        (!contentHTML)
            ? parse("<span>Archivo</span>")
            : (contentHTML.rota)
                ? <a className="file_link" href={"/media" + contentHTML.rota} target="_blank" rel="noopener noreferrer" title={"Abrir " + contentHTML.name}>{contentHTML.name}</a>
                : parse(contentHTML)
    );
};

export default File;
