import React, { useEffect, useContext } from "react";
import "./MainLoader.css"

const MainLoader = () => {

  useEffect(() => {
    document.body.classList.add('no-scroll');
  }, []);

  return (
    <div id="main_loader">
      <div className="loader_center">
        <div className="loader_in">Cargando...</div>
      </div>
    </div>
  );
};
export default MainLoader;