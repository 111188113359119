let Components = {};

Components['Intro'] = require('./Intro').default;
Components['News'] = require('./News').default;
Components['Events'] = require('./Events').default;
Components['Wide'] = require('./Wide').default;
Components['Links'] = require('./Links').default;
Components['Video'] = require('./Video').default;
Components['Info'] = require('./Info').default;
Components['Quote'] = require('./Quote').default;
Components['Partner'] = require('./Partner').default;
Components['Animaciones'] = require('./Animaciones').default;

export default Components; 