import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  useParams,
  Route,
  useNavigate,
} from "react-router-dom";

import { TopBack, BodyContent } from "./fixed/Fixed";
import axios from "axios";

import { LoadingContext } from "../Main";
import check_accordeon from "../../admin/template/check_accordeon";

const Contenido = () => {
  const { startLoading } = useContext(LoadingContext);
  const { cancelLoading } = useContext(LoadingContext);
  const [dataContent, setDataContent] = useState([]);
  const [dataTopBack, setDataTopBack] = useState([]);
  const [submenu, setDataSubmenu] = useState([]);
  const { Link } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (dataContent.length) {
      check_accordeon();
    }
  }, [dataContent]);

  useEffect(() => {
    startLoading();
    const formData = new FormData();
    formData.append("link", Link);
    axios
      .post("/contenido/load", formData)
      .then((response) => {
        if (response.data.success) {
          setDataContent(JSON.parse(response.data.current.codigo));
          setDataTopBack(response.data.current);
          setDataSubmenu(
            response.data.current.submenu_submenu
              ? JSON.parse(response.data.current.submenu_submenu)
              : []
          );
          cancelLoading();
        } else {
          navigate("/404");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
      //cleanup
      setDataContent([]);
      setDataTopBack(null);
      setDataSubmenu([]);
    };
  }, [Link]);

  return (
    <>
      {dataTopBack && (
        <TopBack backName="Inicio" dataTopBack={dataTopBack} backTo="/" />
      )}

      {dataContent.length && (
        <BodyContent dataContent={dataContent} submenu={submenu} />
      )}
    </>
  );
};
export default Contenido;
