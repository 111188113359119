import React, { useEffect } from 'react';
import './styles/wide.css'; // Importar el archivo CSS
import Section from './Animaciones';

export default function Wide() {
  useEffect(() => {}, []);

  return (
    <Section className="slide" data-s="carousel">
      <div className="slide_title">
        Nuestro Campus
      </div>

      <div className="box_carousel">
        <div className="self_carousel">
          {/* Reemplazar imagen por video */}
          <div className="img_carousel">
            <video
              autoPlay
              muted
              loop
            >
              <source src="https://new.ufpso.edu.co/media/principal/intro/intro2.mp4" type="video/mp4" />
              Tu navegador no soporta la reproducción de videos.
            </video>
          </div>

          {/* Texto sobre el video */}
          <div className="txt_carousel">
            <div>
              Bienvenido al campus de la Universidad Francisco de Paula Santander - Ocaña, rodeado de impresionante naturaleza. Aquí, podrás disfrutar de un
              ambiente tranquilo y relajante mientras amplías tus conocimientos y habilidades. Con instalaciones modernas y un personal dedicado, te garantizamos
              una experiencia universitaria inolvidable.
            </div>
            <span className="read">
              <a href="https://new.ufpso.edu.co/campus" className="view_all_button_wide" target="_blank">
                <span className="material-icons white">east</span>
                Leer más
              </a>
            </span>
          </div>
        </div>
      </div>
    </Section>
  );

  return (
    <section className="slide" data-s="carousel">
      <div className="slide_title">Nuestro Campus</div>
      <div className="box_carousel">
        <div className="self_carousel">
          <div className="img_carousel">
            <picture>
              <img src="/media/contenido/cabezera/casatime.jpg" />
            </picture>
          </div>
          <div className="txt_carousel">
             {/* <h2>Lorem Ipsum</h2>  */}
            <div className="resume">
              Bienvenido al campus de la Universidad Francisco de Paula Santander - Ocaña, rodeado de impresionante naturaleza. Aquí, podrás disfrutar de un ambiente tranquilo y relajante mientras amplías tus conocimientos y habilidades. Con instalaciones modernas y un personal dedicado, te garantizamos una experiencia universitaria inolvidable.
            </div>
            <span className="read">
              <span className="material-icons white">east</span>Leer más</span>
          </div>
        </div>
      </div>
    </section>

  );
}
