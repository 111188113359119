import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/aside.css";
import { motion } from "framer-motion";

const Aside = () => {
  const navigate = useNavigate();
  
  // Funciones de navegación
  // const menuBuscar = () => navigate("/buscador");
  const go_home = () => navigate("/");
  const servState = () => navigate("/servicios");
  const calendario = () => navigate("/calendario_academico");
  const mail_u = () => window.open("https://mail.google.com/a/ufpso.edu.co/", "_blank");

  // Datos de los botones para mapearlos dinámicamente
  const buttons = [
    { id: "home", icon: "home", title: "Inicio", onClick: go_home },
   // { id: "search", icon: "search", title: "Buscar", onClick: menuBuscar },
    { id: "services", icon: "view_agenda", title: "Servicios", onClick: servState },
    { id: "calendar", icon: "calendar_today", title: "Calendario", onClick: calendario },
    { id: "email", icon: "email", title: "Correo Institucional", onClick: mail_u },
    // Puedes agregar más botones aquí
  ];

  return (
    <div className="side-bar">
      {buttons.map((btn) => (
        <div key={btn.id} className="side-button-container" title={btn.title}>
          <motion.span
            className="material-icons"
            onClick={btn.onClick}
            whileHover={{ scale: 1.5 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            {btn.icon}
          </motion.span>
          {/* Recuadro que aparece al hacer hover */}
          <div className="hover-box">
            <span className="material-icons large-icon">{btn.icon}</span>
            <span className="hover-title">{btn.title}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Aside;
