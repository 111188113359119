import React from "react";
import ReactDOM from 'react-dom/client';
import Main from './main/Main'


const root = ReactDOM.createRoot(document.getElementById('index'));

function IndexMain() { return (<Main />); }

root.render(<IndexMain />); 
