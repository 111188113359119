import React from 'react';
import parse from 'html-react-parser';

const Image = ({ contentHTML }) => {

  return (
    (!contentHTML)
      ? parse("<span>Imagen</span>")
      : parse(contentHTML)
  ); 
};

export default Image; 