import React from 'react';
import parse from 'html-react-parser';

const Table = ({ contentHTML }) => {

  return (
    (contentHTML)
      ? parse(contentHTML)
      : parse("<span>Tabla</span>")

  );
};

export default Table;
