import React, { useState, useEffect } from "react";
import Section from "./Animaciones";
import "./styles/partner.css";
import { motion } from "framer-motion";

export default function Partner() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const logosPerPage = 5;

  const logos = [
   {link: "http://www.mineducacion.gov.co/sistemasdeinformacion/1735/w3-propertyname-2895.html", img: "https://ufpso.edu.co/images/pie_amigas/logo_spadies.png", alt: "Logo spadies" },
   { link: "http://www.dps.gov.co", img: "https://ufpso.edu.co/images/pie_amigas/logo_dps.png", alt: "Logo dps" },
   { link: "https://www.contratos.gov.co/puc/buscador.html", img: "https://ufpso.edu.co/images/pie_amigas/logo_secop.png", alt: "Logo secop" },
   { link: "https://www.funcionpublica.gov.co/", img: "https://ufpso.edu.co/images/pie_amigas/logo_dafp.png", alt: "Logo dafp" },
   { link: "http://www.suit.gov.co/inicio", img: "https://ufpso.edu.co/images/pie_amigas/logo_suit.png", alt: "Logo suit" },
   { link: "http://jovenesenaccion.dps.gov.co/", img: "https://ufpso.edu.co/images/pie_amigas/logo_jovenes.png", alt: "Logo Jovenes" },
   { link: "http://www.rcpcolombia.com/web/", img: "https://ufpso.edu.co/images/pie_amigas/Logo_RCP1.png", alt: "Logo RCP" },
   { link: "http://www.ufps.edu.co/ufps/index.php", img: "https://ufpso.edu.co/images/pie_amigas/logo_ufps17.png", alt: "Logo UFPS" },
   { link: "http://www.mineducacion.gov.co/1759/w3-channel.html", img: "https://ufpso.edu.co/images/pie_amigas/logo_men2.png", alt: "Logo MEN" },
   { link: "https://www.gov.co/", img: "https://ufpso.edu.co/images/pie_amigas/logo_nomasfilas2.png", alt: "Logo No Más Filas" },
   { link: "https://ufpso.edu.co/images/pie_amigas/logo_sies.png", img: "https://ufpso.edu.co/images/pie_amigas/logo_sies.png", alt: "Logo SIES" },
   { link: "http://www.icetex.gov.co/dnnpro5/es-co/inicio.aspx", img: "https://ufpso.edu.co/images/pie_amigas/logo_icetex2.png", alt: "Logo ICETEX" },
   { link: "http://www.icfes.gov.co/", img: "https://ufpso.edu.co/images/pie_amigas/logo_icfes2.png", alt: "Logo ICFES" },
   { link: "http://www.unired.edu.co/", img: "https://ufpso.edu.co/images/pie_amigas/logo_unired2.png", alt: "Logo Unired" },
   { link: "http://aprende.colombiaaprende.edu.co/cainicio", img: "https://ufpso.edu.co/images/pie_amigas/logo_colombia_aprende2.png", alt: "Logo Colombia Aprende" },
   { link: "http://estrategia.gobiernoenlinea.gov.co/623/w3-channel.html", img: "https://ufpso.edu.co/images/pie_amigas/logo_gobierno_linea2.png", alt: "Logo Gobierno en Linea" },
   { link: "http://www.graduadoscolombia.edu.co/html/1732/channel.html", img: "https://ufp so.edu.co/images/pie_amigas/logo_observatorio_laboral2.png", alt: "Logo Observatorio Laboral" },
   { link: "http://www.mintic.gov.co/portal/604/w3-channel.html", img: "https://ufpso.edu.co/images/pie_amigas/logo_mintic2.png", alt: "Logo MINTIC" },
   { link: "http://www.colciencias.gov.co/", img: "https://ufpso.edu.co/images/pie_amigas/logo_colciencias2.png", alt: "Logo Colciencias" },
   { link: "http://www.dane.gov.co", img: "https://ufpso.edu.co/images/pie_amigas/logo_dane2.png", alt: "Logo DANE" },
   { link: "http://tecnoparque.sena.edu.co/sedes/ocana/Paginas/default.aspx", img: "https://ufpso.edu.co/images/pie_amigas/logo_nodo_ocana2.png", alt: "Logo Nodo Ocana" },
   { link: "http://www.fodesep.gov.co/", img: "https://ufpso.edu.co/images/pie_amigas/logo_fodesep.png", alt: "Logo fodesep" },
   { link: "http://www.mineducacion.gov.co/sistemasdeinformacion/1735/propertyvalue-41698.html", img: "https://ufpso.edu.co/images/pie_amigas/logo_saces.png", alt: "Logo SACES" },
   { link: "http://www.mineducacion.gov.co/sistemasdeinformacion/1735/w3-propertyname-2672.html", img: "https://ufpso.edu.co/images/pie_amigas/logo_snies.png", alt: "Logo snies" }
  ];

  // Desplazamiento automático
  useEffect(() => {
    const interval = setInterval(() => {
      handleForward();
    }, 5000);
    return () => clearInterval(interval); // Limpieza del intervalo
  }, [currentIndex]);

  // Avanza al siguiente grupo de logos
  const handleForward = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (logos.length - logosPerPage + 1));
  };

  // Retrocede al grupo anterior de logos
  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + logos.length - logosPerPage + 1) % (logos.length - logosPerPage + 1));
  };

  // Manejo del arrastre
  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <Section className="slide" data-s="partner">
      <div className="carouselContainer">
        <button className="arrow left" onClick={handleBack}>
          ◄
        </button>
        <div
          className="logosContainer"
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseLeave={handleDragEnd}
        >
          <motion.div
            className="logoSlider"
            initial={{ x: 0 }}
            animate={{ x: -currentIndex * (100 / logosPerPage) + "%" }}
            transition={{ duration: 0.5 }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
          >
            {logos.map((logo, index) => (
              <div className="logoBox" key={index}>
                <a href={logo.link} title={logo.alt} target="_blank" rel="noopener noreferrer">
                  <img src={logo.img} alt={logo.alt} title={logo.alt} />
                </a>
              </div>
            ))}
          </motion.div>
        </div>
        <button className="arrow right" onClick={handleForward}>
          ►
        </button>
      </div>
    </Section>
  );
}
