import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './styles/servicios.css';
import { LoadingContext } from '../../Main';

const Servicios = () => {
  const { cancelLoading } = useContext(LoadingContext);
  const { startLoading } = useContext(LoadingContext);
  const [dataServ, setDataServ] = useState([]);
  const [vidState, setVidState] = useState("pause"); // Estado para el ícono de pausa/reanudar

  useEffect(() => {
    startLoading();
    axios.post('/serv/list').then((response) => {
      if (response.data.success) {
        setDataServ(response.data.data);
        cancelLoading();
      }
    });
    document.querySelector('#fixed_nav').classList.add('blacode');
    return () => document.querySelector('#fixed_nav').classList.remove('blacode');
  }, []);

  // Función para pausar o reanudar el video
  function playPause() {
    const video = document.getElementById("back_video");

    if (video.paused) {
      setVidState("pause"); // Cambiar el ícono a pausa
      video.play();
    } else {
      setVidState("play_arrow"); // Cambiar el ícono a play
      video.pause();
    }
  }

  return (
    dataServ && (
      <div id='servicios' style={{ position: 'relative', overflow: 'hidden' }}>
        {/* Video de fondo */}
        <video 
          id="back_video" // Asigna el id al video para controlarlo
          autoPlay 
          muted 
          loop 
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: -1,
          }}
        >
          <source src="https://new.ufpso.edu.co/media/principal/intro/intro2.mp4" type="video/mp4" />
          Tu navegador no soporta videos.
          
        </video>
      

        <div className="title_servicios">Servicios</div>

        <button className="control-video-btn">
          <span onClick={playPause}
            className="material-icons"
            id="control_video"
          >
            {vidState}
          </span> 
        </button>

        <div className='servicios_box' style={{ zIndex: 1 }}>
          {dataServ &&
            dataServ.map((box) => 
              box.activo == '1' ? (
                <a
                  key={box.id}
                  href={box.link}
                  target='_blank'
                  className='servicios_item'
                >
                  <span className='material-icons'>display_settings</span>
                  <span className='servicios_name'>
                    {box.nombre[0].toUpperCase() + box.nombre.substring(1)}
                  </span>
                </a>
              ) : null
            )}
        </div>
      </div>
    )
  );
};

export default Servicios;