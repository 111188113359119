import React, { useEffect, useState } from 'react';
import Section from './Animaciones';


export default function Video() {
    const [videoActive, setVideoActive]=useState(0);
    const dataVideos= [
        {
            url:"QpaWpP0xMK4?si=gyzZX8hjOnFGvWv1",
            name:"Informativo Así va la U"
        },
        {
            url:"GSk91Pr4o28?si=MXrhzvylXzxVoMRy",
            name:"Dirección Informa"
        }
    ];

    return (
        <Section className="slide" data-s="basic">
            <div className="slide_title">{dataVideos[videoActive].name}</div>
            <div className="basic_txt">
            <div className="resume">
                <span className="read" onClick={()=>{setVideoActive(0)}}><span className="material-icons">play_arrow</span>Informativo Así va la U</span>
                <span className="read" onClick={()=>{setVideoActive(1)}}><span className="material-icons">play_arrow</span>Dirección Informa</span>
            </div>
        </div>
            <div className="basic_box">
                <iframe 
                    src={`https://www.youtube.com/embed/${dataVideos[videoActive].url}?controls=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1`} 
                    frameBorder="0" 
                    width="100%"  // Ancho del video en píxeles
                    height="100%"
                    allowFullScreen
                >
                </iframe>
            </div>
        </Section>
    );
}


{/* <div className="basic_box">

<picture className="utv">
    <img src="/media/general/logos/utv.png" />
</picture>
<div className="basic_img">
    <iframe src="https://www.youtube.com/embed/u_fFjB8t_SY?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1" frameBorder="0" allowFullScreen></iframe>
</div>

<div className="basic_txt">
    <h2>Informativo Así va la U</h2>
    <div className="resume">
        <span className="read"><span className="material-icons">play_arrow</span>Informativo Así va la U</span>
        <span className="read"><span className="material-icons">play_arrow</span>En contexto con la U</span>
        <span className="read"><span className="material-icons">play_arrow</span>Promos UFPSO</span>
        <span className="read"><span className="material-icons">play_arrow</span>Boletín 'Tu Vos Universitario'</span>
    </div>
</div>
</div> */}
