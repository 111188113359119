import React from 'react';
import parse from 'html-react-parser';

const Link = ({ contentHTML, }) => {

    return (
        (!contentHTML)
            ? parse("<span>Enlace</span>")
            : (contentHTML.rota)
                ? <a className="file_link" href={contentHTML.rota} target="_blank" rel="noopener noreferrer" title="Abrir enlace">[Texto de ejemplo]</a>
                : parse(contentHTML)
    );
};

export default Link;
