import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, useLocation, useNavigate  } from "react-router-dom";
import Components from "./componentes/Componentes";
import axios from "axios";
import { LoadingContext } from "../Main";

//import './componentes/styles/news.css';
import './componentes/styles/news.css';
import './componentes/styles/carousel.css';
import './componentes/styles/video.css';
import './componentes/styles/quote.css';
import './componentes/styles/links.css';
import './componentes/styles/intro.css';
import './componentes/styles/info.css';
import './componentes/styles/events.css';
import './componentes/styles/partner.css';


const Home = ({ setAtHome }) => {
  const { startLoading } = useContext(LoadingContext);
  const { cancelLoading } = useContext(LoadingContext);
  const location = useLocation();
  const navigate = useNavigate ();
  const [dataMain, setDataMain] = useState([]);


  useEffect(() => {
    if (location.pathname === "/") {
      setAtHome(location.pathname);
    }
    return () => {
      setAtHome("");
    };
  }, [location.pathname]);

  useEffect(() => {
    
    startLoading();

    axios.post("/principal/get").then((response) => {
      if (response.data.success) {
        let newData = [];
        response.data.data.map((sec) => {
          newData.push({
            id: sec.id,
            nombre: Components[titleCase(sec.nombre)],
            activo: sec.activo,
            orden: sec.orden,
          });
        });
        setDataMain(newData);
        cancelLoading();
      } else {
        navigate("/404");
      }
    })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      setDataMain([]);
    };
  }, []);

  const titleCase = (string) => {
    return string[0].toUpperCase() + string.substr(1).toLowerCase();
  };

  return (
    dataMain &&
    dataMain
      .sort((a, b) => a.orden - b.orden)
      .map((Sec) => (Sec.activo ? <Sec.nombre key={Sec.id} /> : null))
  );
};
export default Home;
