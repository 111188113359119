import React, { useEffect, useContext } from 'react';
import { LoadingContext } from '../../Main';
import './styles/404.css';

const Error404 = () => {

  const { cancelLoading } = useContext(LoadingContext);


  useEffect(() => {
    document.body.classList.remove('no-scroll');
    document.querySelector('#fixed_nav').classList.add('blacode');
    cancelLoading();
    return () => document.querySelector('#fixed_nav').classList.remove('blacode');
  }, []);

  return (
    <div className="info_content" id="error404">
      <span>ERROR 404</span>
      <span>Página no encontrada</span>
      <span>
        La URL no existe, pero podemos ayudarte a encontrar lo que estas <a href="/buscar">buscando</a>. O puedes ponerte en <a href="/contacto">contacto</a> con nosotros.
      </span>
    </div>
  );
};
export default Error404;
