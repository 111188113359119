import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HistoricoNoticias from "../../contenido/fixed/HistoricoNoticias";
// import ScrollMagic from 'scrollmagic'
import { motion, spring } from "framer-motion";

export default function Intro() {
  const [vid, vidboton] = useState("pause");
  const [windowHeight, setWindowHeight]= useState(window.innerHeight);

  useEffect(()=>{
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[])


  useEffect(() => {
    // document.title = "UFPS Ocaña - Inicio";
    // var controller = new ScrollMagic.Controller();
    // // build scenes
    // new ScrollMagic.Scene({ triggerElement: "section[data-s='inicio']", offset: 950 })
    //     .setClassToggle("section[data-s='inicio']", "visible") // add class toggle
    //     .addTo(controller);
  }, []);

  function playPause() {
    var video = document.getElementById("back_video");

    if (video.paused) {
      vidboton("pause");
      video.play();
    } else {
      vidboton("play_arrow");
      video.pause();
    }
  }

  function goto() {
    window.open("https://aspirante.ufpso.edu.co/app/#inicio", "_blank");
  }

  const scrollTO=()=>{
    window.scroll({
      top:windowHeight,
      left:0,
      behavior:"smooth",
    })
  }

  const data = [
    { id: "New", onclick: "CreateNewDoc()" },
    { value: "Open", onclick: "OpenDoc()" },
    { value: "Close", onclick: "CloseDoc()" },
  ];

  return (
    <section className="slide" data-s="inicio">
      <video autoPlay muted loop id="back_video">
        <source src="/media/principal/intro/intro-vida.mp4" type="video/mp4" />
      </video>

      <div className="back-shadow"></div>
      <div className="main-title">
        <motion.h2
          initial={{ scale: 0 }}
          transition={{ duration: 1, ease: "easeInOut", delay:0.2, type:'tween' }}
          animate={{ scale: 1 }}
        >
          Bienvenido a la UFPS Ocaña
        </motion.h2>
        <p>
          Inicia tu vida universitaria y construye junto a nosotros tu proyecto
          de vida. 
        </p>
        <motion.div
          className="study-us"
          onClick={goto}
          whileHover={{ scale: 1.1 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
      style={{
        borderRadius: "10px"
      }}
        >
          <span className="material-icons">school</span>Estudia con nosotros
        </motion.div>
      </div>

      <span
        className="material-icons noselect"
        id="control_video"
        onClick={playPause}
      >
        {vid}
      </span> 
      <motion.span className="material-icons arrow_circle_down" whileHover={{ scale: 2 }}
          whileTap={{ scale: 0.9 }} onClick={scrollTO}>expand_more</motion.span>
    </section>

    
  );
}
