import React, { useState, useEffect } from 'react';
import open_click from '../../template/open_click.js';
import parse from 'html-react-parser';

const AccordionBase = ({ contentHTML = "" }) => {


   useEffect(() => {
      //obtenemos el boton y asignamos el evento de abrir y cerrar toggle
      var acc = document.querySelectorAll(".folder_button");
      for (var i = 0; i < acc.length; i++) {
         // verifica si ya tiene evento asignado
         if (!acc[i].clickEventListenerAttached) {
            // asignamos el evento
            open_click(acc[i]);
            // activamos la bandera para evitar que reasigne el evento
            acc[i].clickEventListenerAttached = true;
         }
      }
   }, []);


   return (
      (!contentHTML)
         ? parse('<div className="folder_button closed">Edite este texto según sea necesario</div>')
         : parse(contentHTML)
   );
}

export default AccordionBase; 
