export function menuToggle() {
    // variables
    var but = document.getElementById("hamburger");
    var nav = document.querySelector("#cabezera");
    var subnav = document.querySelector(".subNav.active");
    var body = document.body;
    var fixed = document.getElementById("fixed_nav");
    var black = document.querySelector("#fixed_nav.black");
    var oth = document.querySelectorAll(".topNav li");
    var main = document.querySelector("#main-menu");
    var li = document.querySelector("#menu .aside-left ul li");
    var lis = document.querySelectorAll("#menu .aside-left ul li");
    // reset class
    body.classList.toggle("noscroll");
    nav.classList.toggle("active");
    // validamos + reset
    if (black) { black.classList.remove("black"); }
    if (subnav) { subnav.classList.remove("active"); }

    oth.forEach((item) => {
        item.classList.remove("not-active");
        item.classList.remove("active");
    });

    if (fixed.classList.contains("scrolled")) {
        if (fixed.classList.contains("black")) {
            fixed.classList.remove("black");
        }
    }

    // activamos
    if (nav.className.split(" ").indexOf("active") >= 0) {
        but.classList.add("active");
        main.classList.add("active");
        if (li) {
            setTimeout(function () {
                lis.forEach(function (item, i) {
                    setTimeout(function () { item.classList.add('li_on'); }, (i + 1) * 35);
                });
            }, 12);
            setTimeout(function () { li.click(); }, 450);
        }
    } else {
        but.classList.remove("active");
        main.classList.remove("active");
        lis.forEach(function (item, i) {
            item.classList.remove('li_on');
        })

        if (fixed.classList.contains("scrolled")) {
            if (!fixed.classList.contains("black")) {
                fixed.classList.add("black");
            }
        }

    }

    if (fixed.classList.contains("blacode")) {
        fixed.classList.remove("blacode");
    } else {
        switch (location.pathname) {
            case "/buscador":
                fixed.classList.add('blacode');
                break;
            case "/servicios":
                fixed.classList.add('blacode');
                break;
            case "/404":
                fixed.classList.add('blacode');
                break;
        }
    }
}