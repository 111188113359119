import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate  } from "react-router-dom";
import axios from 'axios';
import Loader from "../../../admin/template/Loader";
import { LoadingContext } from '../../Main';
import './styles/buscador.css';

const Buscador = () => {

  const { cancelLoading } = useContext(LoadingContext);
  const navigate = useNavigate ();
  const [results, setResults] = useState();
  const [Loading, setLoader] = useState(false);

  useEffect(() => {
    document.querySelector('#fixed_nav').classList.add('blacode');
    cancelLoading();
    return () => document.querySelector('#fixed_nav').classList.remove('blacode');
  }, []);

  function keyPress(e) {
    if (e.keyCode == 13) {
      setLoader(true);
      var formData = new FormData();
      formData.append("buscar", e.target.value);
      axios.post('/buscar', formData).then((response) => {
        if (response.data.success == true) {
          setResults(response.data.data)
          setLoader(false)
        }
      });
    }
  }

  function goTo(nav) {
    navigate(nav);
  }

  return (
    <div id="busqueda" >
      <div className="busqueda_box">
        <label>Buscar</label>
        <span className="material-icons">search</span>
        <input type="text" onKeyDown={(e) => keyPress(e)} />
      </div>
      <div className="resultados_box">
        {(!Loading) ?
          (results) ?
            (results.length) ?
              results.map((box, i) => (
                <div
                  key={i}
                  className='resultado_item'
                  onClick={() => goTo(box.navegacion)}>
                  <div className='titulo'>{box.titulo}</div>
                  <div className='resumen'>{box.resumen}</div>
                  <div className='publicado'>{box.publicado.date.substring(0, 10)}</div>
                </div>
              )) : <div className='none'>No hay resultados.</div>
            : null
          : <Loader />}
      </div>
    </div>
  );
};
export default  Buscador;
